import * as React from 'react'
import { IImage } from 'interfaces/media'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import Head from 'next/head'

interface IProps {
    backgroundImage: IImage
    mobileBackgroundImage: IImage
    className?: string
}

const smBreakPoint = Number(screens.sm.replace('px', ''))

const generateDesktopHeroImageSrcSet = (url: string, format = 'jpg', quality = 90) => {
    return `
        ${url}?w=768&fm=${format}&q=${quality} 768w,
        ${url}?w=1280&fm=${format}&q=${quality} 1280w,
        ${url}?w=1440&fm=${format}&q=${quality} 1440w,
        ${url}?w=1600&fm=${format}&q=${quality} 1600w
    `
}

const generateMobileHeroImageSrcSet = (url: string, format = 'jpg', quality = 90) => {
    return `
        ${url}?w=360&fm=${format}&q=${quality} 360w,
        ${url}?w=420&fm=${format}&q=${quality} 420w,
        ${url}?w=640&fm=${format}&q=${quality} 640w
    `
}
export const HeroImage = ({ backgroundImage, mobileBackgroundImage, className = '' }: IProps): React.ReactElement => {
    return (
        <>
            <Head>
                {/* preload hero image */}
                {/* we're only prealoading webp image formats,
                    this does mean that browsers that don't support webp won't preload the hero image
                    webp has great browser support now, only IE and safari for macOS < 11 don't support it
                    ** so preload may not work for all users but it will work for most of the users (hopefully)
                */}
                <link
                    media={`(max-width: ${smBreakPoint - 1}px)`}
                    rel="preload"
                    as="image"
                    href={`${mobileBackgroundImage?.url}?fm=webp&w=640&q=50`}
                    type="image/webp"
                    // don't use camel casing as react does not recognize imageSrcSet in link element
                    // @ts-ignore
                    imageSrcSet={generateMobileHeroImageSrcSet(mobileBackgroundImage?.url, 'webp')}
                />
                <link
                    media={`(min-width: ${smBreakPoint}px)`}
                    rel="preload"
                    as="image"
                    href={`${backgroundImage?.url}?fm=webp&w=1024&q=50`}
                    type="image/webp"
                    // @ts-ignore
                    imageSrcSet={generateDesktopHeroImageSrcSet(backgroundImage?.url, 'webp')}
                />
            </Head>
            <picture>
                {/* large screens */}
                {/* webp */}
                <source
                    type="image/webp"
                    media={`(min-width: ${smBreakPoint}px)`}
                    srcSet={generateDesktopHeroImageSrcSet(backgroundImage?.url, 'webp')}
                />
                {/* jpg */}
                <source
                    type="image/jpeg"
                    media={`(min-width: ${smBreakPoint}px)`}
                    srcSet={generateDesktopHeroImageSrcSet(backgroundImage?.url)}
                />
                {/* small screens */}
                {/* webp */}
                <source type="image/webp" srcSet={generateMobileHeroImageSrcSet(mobileBackgroundImage?.url, 'webp')} />
                {/* jpg */}
                <source type="image/jpeg" srcSet={generateMobileHeroImageSrcSet(mobileBackgroundImage?.url)} />
                <img
                    width={mobileBackgroundImage?.width ?? backgroundImage?.width}
                    alt={mobileBackgroundImage?.title ?? backgroundImage?.title}
                    height={mobileBackgroundImage?.height ?? backgroundImage?.height}
                    src={`${mobileBackgroundImage?.url ?? backgroundImage?.url}?w=1024&fm=jpg&q=70`}
                    className={className}
                />
            </picture>
        </>
    )
}
