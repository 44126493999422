/* eslint-disable react/display-name */
/* HERO FOR SOCIAL PROOF V1 */
/* Some values may be hard coded */
/* Note that this component + render type will likely be altered or removed in the near future */
import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionHero } from 'interfaces/page-section-hero'
import { BLOCKS } from '@contentful/rich-text-types'
import Link from 'next/link'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import { IUiResource, IUiResources } from 'interfaces/ui-resource'
import HeroVideo from 'components/hero-video'
import HeroImage from 'components/hero-image'
import { useFeature } from '@growthbook/growthbook-react'
import {
    shippingThresholdExperimentName,
    shippingThresholdExperimentVariationOne,
    shippingThresholdExperimentVariationTwo,
} from 'misc-variables'

interface IProps {
    pageSectionHero: IPageSectionHero
    playMediaLabel: IUiResource
    uiResources: IUiResources
}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <div className="text-2xl lg:text-5xl text-black leading-120% tracking-tight whitespace-nowrap">{children}</div>
    ),
}

const subHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="leading-140%">{children}</p>,
}

export const HeroSectionSplitScreenWithVideo = ({
    pageSectionHero,
    playMediaLabel,
    uiResources,
}: IProps): React.ReactElement => {
    const {
        richHeader,
        richSubHeader,
        primaryCtaLink,
        mobileBackgroundImagesCollection,
        backgroundImagesCollection,
        renderType,
    } = pageSectionHero

    const isMobileScreen = useMobileScreenDetection()

    const mobileMedia = mobileBackgroundImagesCollection.items?.[0]
    const desktopMedia = backgroundImagesCollection.items?.[0]
    const socialIcons = backgroundImagesCollection.items?.[1]
    const backgroundImage = backgroundImagesCollection.items?.[2]
    const socialIconsMobile = backgroundImagesCollection.items?.[3]

    const hasVideo = mobileMedia?.contentType?.includes('video') || desktopMedia?.contentType?.includes('video')

    const showShippingThresholdExperiment = useFeature(shippingThresholdExperimentName).value || 'control'

    return (
        <>
            <div className={`${showShippingThresholdExperiment === 'control' ? 'hidden' : 'block'}`}>
                <div className="w-full bg-sleepStress text-white flex justify-center py-1.5 text-xs">
                    {showShippingThresholdExperiment === shippingThresholdExperimentVariationOne && (
                        <p>{uiResources['socialProofLPDiscountExpLabel30']?.value}</p>
                    )}

                    {showShippingThresholdExperiment === shippingThresholdExperimentVariationTwo && (
                        <p>{uiResources['socialProofLPDiscountExpLabel40']?.value}</p>
                    )}
                </div>
            </div>

            <section className={`grid grid-cols-1 lg:pb-10 lg:grid-cols-2 grid-rows-1 mb-20`}>
                {/* content */}
                {renderType === 'content left, video right' && (
                    <div className="px-5 lg:py-28 flex flex-col items-center lg:bg-white z-10">
                        <div className="py-10 lg:py-0 lg:px-20">
                            <h2 className="grid grid-cols-1">
                                <span className="uppercase text-xl lg:text-2xl font-bold">Join The</span>
                                <span className="uppercase text-6.25xl font-bold lg:text-7xl text-painFever">700k</span>
                                <ContentfulRichText content={richHeader.json} renderNode={headerRenderNode} />
                            </h2>

                            {richSubHeader?.json && (
                                <div className="mt-2 lg:mt-6 text-xs lg:text-lg whitespace-nowrap">
                                    <ContentfulRichText
                                        content={richSubHeader.json}
                                        renderNode={subHeaderRenderNode}
                                        ignoreMarkdownStyles
                                    />
                                </div>
                            )}

                            {/* cta */}
                            {primaryCtaLink?.href && (
                                <Link
                                    href={primaryCtaLink.href}
                                    className="w-full whitespace-nowrap md:section--primary-cta button bg-painFever text-white lg:border-current inline-block mt-4 lg:mt-4 sm:mt-auto sm:w-auto lg:py-2.5 text-xs lg:text-base wh"
                                >
                                    {primaryCtaLink.name}
                                </Link>
                            )}
                        </div>
                    </div>
                )}

                {renderType === 'content left, video right, social' && (
                    <div className="px-5 lg:py-28 flex flex-col items-center lg:bg-white z-10 relative">
                        <img
                            src={backgroundImage?.url}
                            alt={backgroundImage?.title}
                            height={backgroundImage?.height}
                            width={backgroundImage?.width}
                            className="h-full w-full object-cover max-h-[500px] left-0 -z-10 absolute"
                        />

                        <div className="grid grid-cols-3 py-10 lg:py-0 relative w-auto lg:w-full xl:px-20">
                            <div className="col-span-2 py-5 lg:py-0">
                                {/* social proof */}
                                {isMobileScreen ? (
                                    // mobile
                                    <img
                                        src={socialIconsMobile?.url}
                                        alt={socialIconsMobile?.title}
                                        height={110}
                                        width={110}
                                        className="absolute top-8 right-0 object-contain -z-10"
                                    />
                                ) : (
                                    // desktop
                                    <img
                                        src={socialIcons?.url}
                                        alt={socialIcons?.title}
                                        height={140}
                                        width={140}
                                        className="absolute top-0 right-0 object-contain -z-10"
                                    />
                                )}
                                <h2 className="uppercase text-xl lg:text-2xl font-bold">Join The</h2>
                                <h2 className="uppercase text-6.25xl font-bold lg:text-7xl text-painFever">700k+</h2>
                                {richHeader?.json && (
                                    <h2>
                                        <ContentfulRichText content={richHeader.json} renderNode={headerRenderNode} />
                                    </h2>
                                )}

                                {richSubHeader?.json && (
                                    <div className="mt-2 lg:mt-6 text-xs lg:text-lg whitespace-nowrap">
                                        <ContentfulRichText
                                            content={richSubHeader.json}
                                            renderNode={subHeaderRenderNode}
                                            ignoreMarkdownStyles
                                        />
                                    </div>
                                )}

                                {/* cta */}
                                {primaryCtaLink?.href && (
                                    <Link
                                        href={primaryCtaLink.href}
                                        className="w-full whitespace-nowrap md:section--primary-cta button bg-painFever text-white inline-block mt-4 lg:mt-4 sm:mt-auto sm:w-auto lg:py-2.5 text-xs lg:text-base"
                                    >
                                        {primaryCtaLink.name}
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {renderType === 'content left, video right, social - alt' && (
                    <div className="px-5 lg:py-28 flex flex-col items-center lg:bg-white z-10 relative">
                        <img
                            src={backgroundImage?.url}
                            alt={backgroundImage?.title}
                            height={backgroundImage?.height}
                            width={backgroundImage?.width}
                            className="h-full w-full object-cover max-h-[350px] left-0 -z-10 absolute"
                        />

                        <div className="grid grid-cols-3 py-10 lg:py-0 relative w-auto lg:w-full xl:px-20">
                            <div className="col-span-2 py-5 lg:py-0">
                                {/* social proof */}
                                {isMobileScreen ? (
                                    // mobile
                                    <img
                                        src={socialIconsMobile?.url}
                                        alt={socialIconsMobile?.title}
                                        height={80}
                                        width={80}
                                        className="absolute top-0 inset-y-0 my-auto right-0 object-contain -z-10"
                                    />
                                ) : (
                                    // desktop
                                    <img
                                        src={socialIcons?.url}
                                        alt={socialIcons?.title}
                                        height={140}
                                        width={140}
                                        className="absolute inset-y-0 my-auto right-0 top-0 object-contain -z-10 pl-4"
                                    />
                                )}
                                {richHeader?.json && (
                                    <h2>
                                        <ContentfulRichText content={richHeader.json} renderNode={headerRenderNode} />
                                    </h2>
                                )}
                                {richSubHeader?.json && (
                                    <div className="mt-2 lg:mt-6 text-xs lg:text-base">
                                        <ContentfulRichText
                                            content={richSubHeader.json}
                                            renderNode={subHeaderRenderNode}
                                            ignoreMarkdownStyles
                                        />
                                    </div>
                                )}

                                {/* cta */}
                                {primaryCtaLink?.href && (
                                    <Link
                                        href={primaryCtaLink.href}
                                        className="w-full whitespace-nowrap md:section--primary-cta button bg-painFever text-white mt-4 lg:mt-6 sm:mt-auto inline-block sm:w-auto lg:py-2.5 text-xs lg:text-base"
                                    >
                                        {primaryCtaLink.name}
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {/* video */}
                {hasVideo ? (
                    <HeroVideo
                        className="h-full w-full object-cover max-h-[450px] lg:max-h-[none]"
                        video={backgroundImagesCollection?.items?.[0]}
                        playMediaLabel={playMediaLabel}
                    />
                ) : (
                    <HeroImage
                        className="h-full w-full object-cover object-right-bottom absolute top-0 left-0 lg:static"
                        backgroundImage={backgroundImagesCollection?.items?.[0]}
                        mobileBackgroundImage={mobileBackgroundImagesCollection?.items?.[0]}
                    />
                )}
            </section>
        </>
    )
}
