import * as React from 'react'
import { IVideo } from 'interfaces/media'
import Video from 'components/video/video'
import globalManifest from 'data/global-manifest.json'
import Head from 'next/head'
import { LOADING } from 'enum/loading'
import { IUiResource } from 'interfaces/ui-resource'
import { notAllowedErrorName } from 'misc-variables'
import * as Sentry from '@sentry/nextjs'

const { resources: globalUIResources } = globalManifest

interface IProps {
    video: IVideo
    poster?: string
    className?: string
    playMediaLabel: IUiResource
}

export const HeroVideo = ({ video, poster, className = '', playMediaLabel }: IProps): React.ReactElement => {
    const ref = React.useRef<HTMLVideoElement>()

    React.useEffect(() => {
        if (!ref.current) {
            return
        }

        ref.current.load()
        const playPromise = ref.current.play()

        playPromise.catch((e) => {
            if (e.name === notAllowedErrorName) {
                Sentry.captureException(e)
            }
        })
    }, [video?.url])

    if (!video?.url) {
        return null
    }

    return (
        <>
            <Head>
                {/* preload hero video */}
                <link rel="preload" as="video" href={video.url} type={video.contentType} />
            </Head>
            <Video
                ref={ref}
                height={video.height}
                width={video.width}
                className={`w-full object-cover ${className}`}
                autoPlay
                muted
                playsInline
                loop
                poster={poster}
                playMediaLabel={playMediaLabel}
                preload="auto"
                loading={LOADING.EAGER}
            >
                <source type={video.contentType} src={video.url} />
                <span>{globalUIResources.browserDoesNotSupportVideoMessage.value}</span>
            </Video>
        </>
    )
}
